<template>
    <el-row :loading="loading" class="fs-lg fw-300 pt-5 pr-5 pl-5 bg-white border-faded rounded mb-g w-100">
        <h2>{{ data.category }}</h2>
        <small v-if="data.updated_at" > Cập nhật lần cuối  {{ data.updated_at | formatDateTime }}</small>
        <el-empty v-if="!data.content" description="Chưa có dữ liệu nào!"></el-empty>
        <el-row v-else :gutter="10" v-html="data.content" class="pr-2 pl-2 pt-3 pb-3 rawHTML"></el-row>
        <div class="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row align-items-center">
            <router-link :to="{ name: 'information-notification'}" v-slot="{ href, navigate}">
                <a class="btn btn-default ml-auto mt-3 mb-3"  :href="href" title="Thông báo" @click="navigate" data-filter-tags="Thông báo">
                    Đóng
                </a>
            </router-link>
        </div>
    </el-row>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {INFORMATION_INFORMATION_SLUG} from "@/core/services/store/service.module";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    export default {
        name: "thong-bao-chi-tiet",
        components: {
        },
        data() {
            return {
                data: [],
                loading: false,
            }
        },
        methods:{
            queryServer(customProperties) {
                this.loading = true;
                let slug = this.$route.params.slug;
                slug = slug.replace(".", "");
                let params = {
                    slug: slug
                };
                params = _.merge(params, customProperties);
                this.$store.dispatch(INFORMATION_INFORMATION_SLUG, params)
                    .then((response) => {
                        if (response.status === 422) {
                            this.$message({type: 'warning', message: `${response.data.message}`, showClose: true});
                        } else {
                            this.data = response.data;
                        }
                        this.loading = false;
                    })
            },

        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Thông báo từ Edutalk", icon: 'far fa-info-square', route: 'information-notification'},
                {title: "Chi tiết thông báo", icon: 'far fa-info-square'},
            ]);
            this.queryServer();
        }
    }
</script>
